<template>
  <q-page>
    <div class="full-width text-left q-px-xl q-py-md bg-indigo-1" style="max-height:400px;overflow:auto;">
      <div class="text-h6">
        Import Data Baru
      </div>
      
      <q-card class="full-width q-mt-sm">
        <q-card-section class="row justify-between items-center">
          <input ref="mycsv" type="file" @change.prevent="selectFile" class="" name="mycsv" />
          <a href="./template_import.csv">
            <q-btn no-caps outline color="indigo-10" label="Download Template" />
          </a>
        </q-card-section>
      </q-card>
      <div v-if="parseCsv" class="q-mt-md">
        <q-markup-table bordered dense class="col-12">
          <thead class="text-blue-grey-14">
            <tr>
              <th style="width:10px;">No</th>
              <th style="width:10px;">No Induk</th>
              <th class="text-left">Nama</th>
              <th class="text-left">Jabatan</th>
              <th class="text-center">Jenis Kelamin</th>
              <th class="text-center">Gedung</th>
              <th class="text-center">No Kamar</th>
              <th class="text-center">Guru Kepala</th>
            </tr>
          </thead>
          <tbody v-if="decodedcsv.length" class="">
            <tr v-for="(d, i) in decodedcsv" :key="i">
              <td class="text-left">{{ i+1 }}</td>
              <td class="text-left">{{ d.no_induk }}</td>
              <td class="text-left">{{ d.nama }}</td>
              <td class="text-left">{{ d.jabatan }}</td>
              <td class="text-center" style="width: 100px;">{{ d.jenis_kelamin }}</td>
              <td class="text-center" style="width: 100px;">{{ d.gedung }}</td>
              <td class="text-center" style="width: 100px;">{{ d.no_kamar }}</td>
              <td class="text-center" style="width: 100px;">{{ d.supervisor }}</td>
            </tr>
          </tbody>
          <tbody v-else class="bg-green-1">
            <tr>
              <td class="text-center text-grey" colspan="99">
                tidak ada data
              </td>
            </tr>
          </tbody>
        </q-markup-table>
        <q-btn
          dense
          color="positive"
          class="q-ma-md"
          style="width: 100px;"
          v-if="parseCsv"
          @click="ImportData"
          label="Import Data"
          no-caps
        />
      </div>
    </div>

    <div class="q-py-md ">
      <div class="q-pa-xl bg-indigo-2">
        <div class="row justify-between items-end">
          <a class="text-h6">
            Data Pendidik
          </a>
          <!-- <q-btn outline color="indigo-10" no-caps label="+ Guru" @click="newGuru = true" /> -->
          <q-input
            outlined
            v-model="search"
            placeholder="Cari guru berdasarkan nama"
            dense
            class="bg-white"
            style="border-radius: 5px; width: 350px;"
            @keypress.enter="fetchGuru('nama')"
          >
            <template v-slot:append>
              <q-btn
                flat
                icon="search"
                class="text-white absolute-right"
                @click="fetchGuru('nama')"
                style="background-color:#03913F"
              />
            </template>
          </q-input>
        </div>
        <div class="row q-py-md">
          <q-markup-table bordered dense class="col-12">
            <thead class="text-blue-grey-14">
              <tr>
                <th style="width:10px;" @click="fetchGuru('no_induk')">No Induk</th>
                <th class="text-left" @click="fetchGuru('nama')">Nama</th>
                <th class="text-center" @click="fetchGuru('jenis_kelamin')">Gender</th>
                <th class="text-center" @click="fetchGuru('supervisor')">Guru Kepala</th>
                <!-- <th class="text-center" @click="fetchGuru('no_kamar')">kamar</th> -->
                <th></th>
              </tr>
            </thead>
            <tbody v-if="listGuru.length" class="">
              <tr v-for="d in listGuru" :key="d.spp_id">
                <td class="text-left">{{ d.no_induk }}</td>
                <td class="text-left">{{ d.nama }}</td>
                <td class="text-center" style="width: 100px;">
                  {{ d.jenis_kelamin }}
                </td>
                <td class="text-center" style="width: 100px;">
                  {{ d.supervisor }}
                </td>
                <!-- <td class="text-center" style="width: 100px;">
                  {{ d.no_kamar }}
                </td> -->
                <td style="width: 100px;">
                  <q-icon @click="editData(d)" name="edit" />
                  <q-icon @click="deleteData(d)" color="red" name="delete_forever" />
                </td>
              </tr>
            </tbody>
            <tbody v-else class="bg-green-1">
              <tr>
                <td class="text-center text-grey" colspan="99">
                  tidak ada data
                </td>
              </tr>
            </tbody>
          </q-markup-table>
        </div>
        <div class="row justify-end">
          <q-pagination
            v-model="activePage"
            :max="Math.ceil(countGuru / 10)"
            input
            input-class="text-orange-10"
            @click="fetchGuru(orderBy)"
          />
        </div>
      </div>
      <div class="q-pa-xl bg-indigo-1">
        <div class="row justify-between items-end">
          <a class="text-h6">

          Data Siswa
          </a>
          <!-- <q-btn outline color="indigo-10" no-caps label="+ Siswa"  @click="newSiswa = true" /> -->
          <q-input
            outlined
            v-model="searchSiswa"
            placeholder="Cari siswa berdasarkan nama"
            dense
            class="bg-white"
            style="border-radius: 5px; width: 350px;"
            @keypress.enter="fetchSiswa('nama')"
          >
            <template v-slot:append>
              <q-btn
                flat
                icon="search"
                class="text-white absolute-right"
                @click="fetchSiswa('nama')"
                style="background-color:#03913F"
              />
            </template>
          </q-input>
        </div>
        <div class="row q-py-md">
          <q-markup-table bordered dense class="col-12">
            <thead class="text-blue-grey-14">
              <tr>
                <th style="width:10px;" @click="fetchSiswa('no_induk')">No Induk</th>
                <th class="text-left" @click="fetchSiswa('nama')">Nama</th>
                <th class="text-center" @click="fetchSiswa('jenis_kelamin')">Gender</th>
                <th class="text-left" @click="fetchSiswa('')">Gedung</th>
                <th class="text-center" @click="fetchSiswa('no_kamar')">kamar</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="listSiswa.length" class="">
              <tr v-for="d in listSiswa" :key="d.spp_id">
                <td class="text-left">{{ d.no_induk }}</td>
                <td class="text-left">{{ d.nama }}</td>
                <td class="text-center" style="width: 100px;">
                  {{ d.jenis_kelamin }}
                </td>
                <td class="text-left">{{ d.gedung }}</td>
                <td class="text-center" style="width: 100px;">
                  {{ d.no_kamar }}
                </td>
                <td style="width: 100px;">
                  <q-icon @click="editData(d)" name="edit" />
                  <q-icon @click="deleteData(d)" color="red" name="delete_forever" />
                </td>
              </tr>
            </tbody>
            <tbody v-else class="bg-green-1">
              <tr>
                <td class="text-center text-grey" colspan="99">
                  tidak ada data
                </td>
              </tr>
            </tbody>
          </q-markup-table>
        </div>
        <div class="row justify-end">
          <q-pagination
            v-model="activePage2"
            :max="Math.ceil(countSiswa / 10)"
            input
            input-class="text-orange-10"
            @click="fetchSiswa(orderBy)"
          />
        </div>
      </div>
    </div>
    <q-dialog v-model="newSiswa" persistent class="row justify-center items-center">
      <q-card class="input-form">
        <q-card-section class="bg-blue-3">
          Input Data Pendidik
        </q-card-section>
        <q-card-section class="row q-gutter-md">
          <q-input outlined dense stack-label v-model="databaru.nama" label="Nama" class="col-grow" />
          <q-input outlined dense stack-label v-model="databaru.no_induk" label="No Induk" class="col-grow" />
        </q-card-section>
        <q-card-section class="row q-gutter-md">
          <q-input outlined dense stack-label v-model="databaru.tempat_lahir" label="Tempat Lahir" class="col-grow" />
          <q-select
            outlined
            dense
            stack-label
            v-model="databaru.jenis_kelamin"
            :options="genderOption"
            map-options
            emit-value
            label="Jenis Kelamin"
            class="col-grow"
          />
        </q-card-section>
        <q-card-section class="row q-gutter-md">
          <q-input outlined dense stack-label v-model="databaru.kelas" label="Kelas" class="col-grow" />

          <q-input outlined dense stack-label v-model="databaru.kode_kelas" label="Kode Kelas" class="col-grow" />
        </q-card-section>
        <q-card-section class="row q-gutter-md">
          <q-input outlined dense stack-label v-model="databaru.no_kamar" label="Nomor Kamar" class="col-grow" />
        </q-card-section>
        <q-card-action>
          <q-btn class="full-width" label="Submit" @click="onSubmit" />
        </q-card-action>
      </q-card>
    </q-dialog>

    <!-- dialog delete -->
    <q-dialog v-model="confirmDelete" persistent>
      <q-card class="q-py-md q-px-sm">
        <q-card-section>
          Apakah akan menghapus data ini?
        </q-card-section>
        <q-card-section class="row justify-evenly">
          <q-btn
            dense
            outline
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="confirmDelete = false"
            label="Tidak"
          />
          <q-btn
            dense
            unelevated
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="deleteData(selectedData)"
            label="Ya"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- dialog edit  -->
    <q-dialog v-model="confirmEdit" persistent>
      <q-card>
        <q-card-section class="text-center text-white" style="background-color:#232979;">
          Form Edit Data Pokok
        </q-card-section>
        <q-card-section style="width: 400px;">
          <label class="text-grey">No Induk</label>
          <q-input
            dense
            outlined
            v-model="selectedData.no_induk"
            class="full-width"
            :rules="[(val) => !!val || 'Wajib diisi']"
          />
          <label class="text-grey">Nama</label>
          <q-input
            dense
            outlined
            v-model="selectedData.nama"
            class="full-width"
            :rules="[(val) => !!val || 'Wajib diisi']"
          />
          <label class="text-grey">Jenis Kelamin</label>

          <q-btn-toggle
            v-model="selectedData.jenis_kelamin"
            class="q-mb-md"
            spread
            no-caps
            toggle-color="deep-orange"
            color="white"
            text-color="black"
            :options="[
              { label: 'Pria', value: 'L' },
              { label: 'Wanita', value: 'P' },
            ]"
          />
          <template v-if="selectedData.jabatan == 'siswa'">
            <label class="text-grey">Gedung</label>
            <q-input
              dense
              outlined
              v-model="selectedData.gedung"
              class="full-width"
              :rules="[(val) => !!val || 'Wajib diisi']"
            />
            <label class="text-grey">No Kamar</label>
            <q-input
              dense
              outlined
              v-model="selectedData.no_kamar"
              class="full-width"
              :rules="[(val) => !!val || 'Wajib diisi']"
            />
          </template>
          <template v-else>
            <label class="text-grey">Supervisor</label>
            <q-input
              dense
              outlined
              v-model="selectedData.supervisor"
              class="full-width"
              :rules="[(val) => !!val || 'Wajib diisi']"
            />
          </template>
        </q-card-section>
        <q-card-section class="row justify-evenly">
          <q-btn
            dense
            outline
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="confirmEdit = false"
            label="Tidak"
          />
          <q-btn
            dense
            unelevated
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="editData(selectedData)"
            label="Ya"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<style></style>

<script>
import moment from "moment";
import Papa from "papaparse";

export default {
  name: "Quarantine",
  data() {
    return {
      search: "",
      searchSiswa: "",

      mycsv: null,
      decodedcsv: [],
      failed: [],

      listGuru: [],
      countGuru: 0,
      listSiswa: [],
      countSiswa: 0,
      orderBy: "",
      orderType: "",
      activePage: 1,
      activePage2: 1,

      newGuru: false,
      newSiswa: false,
      databaru: {},
      genderOption: [
        { value: "L", label: "Laki-laki" },
        { value: "P", label: "Perempuan" },
      ],
      parseCsv: null,
      preview: false,
      confirmDelete: false,
      selectedData: {},
      confirmEdit: false,
    };
  },
  mounted() {
    this.fetchGuru("no_induk");
    this.fetchSiswa("no_induk");
  },
  methods: {
    selectFile() {
      this.mycsv = null;
      this.decodedcsv = [];
      this.readFile((output) => {
        this.mycsv = Papa.parse(output, { skipEmptyLines: true });
        for (let i = 0; i < this.mycsv.data.length - 1; i++) {
          let temp = {
            // old format
            // no_induk: this.mycsv.data[i + 1][0],
            // nama: this.mycsv.data[i + 1][1],
            // jabatan: this.mycsv.data[i + 1][2],
            // jenis_kelamin: this.mycsv.data[i + 1][3],
            // kelas: this.mycsv.data[i + 1][4],
            // kode_kelas: this.mycsv.data[i + 1][5],
            // no_kamar: this.mycsv.data[i + 1][6],
            // supervisor: this.mycsv.data[i + 1][7],

            no_induk: this.mycsv.data[i + 1][0],
            nama: this.mycsv.data[i + 1][1],
            jabatan: this.mycsv.data[i + 1][2],
            jenis_kelamin: this.mycsv.data[i + 1][3],
            gedung: this.mycsv.data[i + 1][4],
            no_kamar: this.mycsv.data[i + 1][5],
            supervisor: this.mycsv.data[i + 1][6],
          };
          if (temp.nama.length > 0) this.decodedcsv.push(temp);
        }
      });
      this.parseCsv = true;
    },
    readFile(callback) {
      let file = this.$refs.mycsv.files[0];

      if (file) {
        let reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = function(evt) {
          callback(evt.target.result);
        };
        reader.onerror = function() {};
      }
    },
    async ImportData() {
      console.log("importing...");
      await this.$http.post("/import_data_pokok", this.decodedcsv, {})
        .then((result) => {

        })



      // await this.$http.get("/list_noinduk", this.decodedcsv[i], {})
      //   .then(async resp => {
      //     var no_induk = await resp.data

      //     for (var i = 0; i < this.decodedcsv.length; i++) {
      //       console.log(this.decodedcsv[i]);
      //       await this.$http.post("/new_data_pokok", this.decodedcsv[i], {})
      //       .then((result) => {})
      //     }

      //   })



      // this.mycsv = null;
      // this.decodedcsv = [];
      // this.parseCsv = false;
      // this.$refs.mycsv.files[0] = null;
    },
    fetchGuru(order) {
      this.orderBy = "nama";
      this.orderType = "ASC";

      var src = "%25";
      if (this.search != "") src = this.search;

      this.$http
        .get("/list_guru/" + src + "/" + this.orderBy + "/" + this.orderType + "/" + (this.activePage - 1) * 10, {})
        .then((result) => {
          this.listGuru = result.data;
          // this.countGuru = result.data.length
        });

      this.$http.get("/count_guru/" + src, {}).then((result) => {
        this.countGuru = result.data.count;
      });
    },
    fetchSiswa(order) {
      this.orderBy = "nama";
      this.orderType = "ASC";

      var src = "%25";
      if (this.searchSiswa != "") src = this.searchSiswa;

      this.$http
        .get("/list_siswa/" + src + "/" + this.orderBy + "/" + this.orderType + "/" + (this.activePage2 - 1) * 10, {})
        .then((result) => {
          this.listSiswa = result.data;
          // this.countSiswa = result.data.length
        });
      this.$http.get("/count_siswa/" + src, {}).then((result) => {
        this.countSiswa = result.data.count;
      });
    },
    onSubmit() {
      this.newGuru = false;
      this.databaru.jabatan = "siswa";
      this.$http.post("/new_siswa", this.databaru, {}).then((result) => {
        this.databaru = {};
      });
    },

    editData(data) {
      if (this.confirmEdit == false) {
        this.selectedData = data;
        this.confirmEdit = true;
      } else {
        //Save Data
        this.$http.put("/edit_data_pokok/" + data.id, data, {}).then((result) => {
          this.fetchGuru("no_induk");
          this.fetchSiswa("no_induk");
          this.selectedData = {};
          this.confirmEdit = false;
        });
      }
    },
    deleteData(data) {
      if (this.confirmDelete == false) {
        this.selectedData = data;
        this.confirmDelete = true;
      } else {
        this.$http.delete("/data_pokok/" + data.id, {}).then((result) => {
          this.fetchGuru("no_induk");
          this.fetchSiswa("no_induk");
          this.selectedData = {};
          this.confirmDelete = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.my-card {
  min-width: 250px;
  background-color: #b9f6ca;
  color: #9e9e9e;
}
.input-form {
  width: 600px;
}

.q-icon {
  color: #03913f;
  font-size: 2em;
  &:hover {
    box-sizing: border-box;
    color: #52ff9a;
  }
  &:active {
    color: #52ff9a;
  }
}
</style>
